@import 'styles/_sass-includes.scss';

.products_carousel_wrapper {
  width: calc(100% + 240px);
  margin-left: -200px;
  padding-left: 200px;
  padding-right: 40px;
  position: relative;
  overflow: hidden;
  padding-top: 64px;
  // media queries
  @include breakpoint($mobile-lg) {
    width: calc(100% + 80px);
    margin: 0;
    margin-left: -40px;
    padding: 64px 40px 0;
  }
  @include breakpoint($mobile-md) {
    width: calc(100% + 50px);
    margin-left: -25px;
    padding: 64px 25px 0;
  }
  @include breakpoint($mobile-sm) {
    width: calc(100% + 32px);
    margin-left: -16px;
    padding: 64px 16px 0;
  }


  &::after,
  &::before {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    // media queries
    @include breakpoint($tablet-sm) {
      display: none;
    }
  }

  &::after {
    content: "";
    right: 0;
    width: 100px;
    background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 1) 100%);
  }

  &::before {
    content: "";
    left: 0;
    width: 200px;
    background: linear-gradient(to left, transparent 0%, rgba(255, 255, 255, 1) 100%);
  }
}

.productsgrid {
  width: 100%;
}

.products_carousel {
  margin-bottom: -1.6rem;

  h3 {
    font-weight: 500;
    margin-bottom: -36px;
    color: var(--neutral-100);
    // media queries
    @include breakpoint($mobile-lg) {
      font-size: 18px;
    }
  }

  .flickity-viewport {
    @include breakpoint($desktop-sm) {
      width: calc(100% + 1.25rem);
      margin-left: -0.75rem;
    }
  }

  .flickity-slider {
    margin-left: -1rem;
    // media queries
    @include breakpoint($desktop-sm) {
      margin-left: 0;
    }
  }

  .product-tile-details {
    font-size: 14px;
    align-items: flex-start;
  }

  .product-tile-category {
    color: var(--neutral-70);
  }

  &.hide-controls .pr_carousel__controls {
    display: none !important;
  }

  .pr_carousel__slide {
    width: 30.33%;
    // media queries
    @include breakpoint($desktop-sm) {
      width: 33.2%;
    }
    @include breakpoint($tablet-lg) {
      width: 40%;
    }
    @include breakpoint($tablet-sm) {
      width: 50%;
    }

    &:last-child {
      padding-right: 2rem;
      width: calc(30.33% + 2rem);
      // media queries
      @include breakpoint($desktop-sm) {
        width: 33.2%;
        padding-right: 0;
      }
      @include breakpoint($tablet-lg) {
        width: 40%;
      }
      @include breakpoint($tablet-sm) {
        width: 50%;
      }
    }
  }

  .pr_disabled__prevbtn .pr_carousel__prevbtn .pr_btn,
  .pr_disabled__nextbtn .pr_carousel__nextbtn .pr_btn {
    background-color: var(--neutral-10);
    color: var(--neutral-60);
  }

  .product-tile.square {
    width: calc(100% - 1rem) !important;
    padding-right: 0.5rem;
  }

  .pr_carousel__controls {
    top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 8px;
  }

  .pr_carousel__prevbtn,
  .pr_carousel__nextbtn {
    position: relative;
    left: unset;
    right: unset;
  }

  .pr_carousel {
    padding-top: 60px;
    position: relative;
    overflow: visible;
    // padding-left: 2px;
    // media queries
    @include breakpoint($desktop-sm) {
      margin-right: 0;
    }
  }

  .pr_btn--lg.pr_btn--icononly {
    padding: 9px;
  }
}
