@import 'styles/_sass-includes.scss';

.skeleton-listing-page {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 40px 0;
  width: 100%;

  .skeleton-thumbnails {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    min-width: 100px
  }

  .listing-details-skeleton {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include breakpoint($mobile-lg) {
      display: grid;
      grid-template-columns: 3fr 1fr;
      height: 10%;
      margin: 0 15px;
    }
  }

  .listing-details-skeleton-container {
    border-radius: 10px;
    margin: 50px 0;

    .listing-details-skeleton-sizes {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  .listing-details-skeleton-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;

    @include breakpoint($mobile-lg) {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    button {
      border-radius: 15px;
      opacity: .2;
      width: 80%;
      justify-content: center;
      height: 50px;

      @include breakpoint($mobile-lg) {
        width: 100%;
      }
    }

    .listing-details-buy {
      background-color: var(--midnight) !important;
      border: 1px solid var(--neutral-30);
    }
    .listing-details-add {
      background-color: var(--pure-white) !important;
      color: var(--midnight) !important;
      border: 1px solid var(--neutral-30);
    }
  }

  .skeleton-mobile {
    height: 100vh;
    width: 100%;

    .listing-details-skeleton-btn-container {
      flex-direction: column;
      margin: 0 15px;
    }
  }

}