@import '~styles/sass-includes';

.header__search {
    background-color: transparent;
    height: 40px;
    gap: 10px;
    display: flex;
    align-items: center;
    border-radius: 200px;
    padding: 0px 12px;
    min-width: 230px;
    border: 1px solid  #262626;

    &:hover {
        background-color:#262626;
    }
}