@import 'styles/_sass-includes.scss';

.recover-page .pr_alert__container {
  max-width: 730px;
  margin: 0 auto;
}

.recover-page__content {
  max-width: 460px;
  width: auto;
  margin: 35px auto 0;
  padding-bottom: 144px;
  display: flex;
  flex-direction: column;

  @include breakpoint($mobile-lg) {
    margin: 54px auto;
    padding-bottom: 0px;
  }

  h2,
  p:not(.text-left) {
    text-align: center;

    @include breakpoint($mobile-lg) {
      text-align: left;
    }
  }

  h2 {
    font-size: 36px;
    text-transform: none;
  }

  p {
    font-size: 14px;
    color: $darkgrey;
  }

  a {
    color: $darkgrey;
    text-decoration: underline;
  }

  .pr_inputfield__label {
    color: $black;
  }

  .spin svg {
    animation: spin 2.3s linear infinite;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}