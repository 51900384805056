.paypaloption {
  position: relative;
  height: 55px;

  .paypal-buttons {
    border-radius: 16px;
    overflow: hidden;
  }
}

.paypaloption__loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #ffc439;
  border-radius: 4px;
}
