.deliveryoptions {
  margin-bottom: 40px;
}

.deliveryoption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &.is-active .deliveryoption__price {
    font-weight: 500;
  }
}

.deliveryoption__namedate {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  

  strong {
    font-weight: 500;
    line-height: 1.4;
    color: var(--neutral-70);
  }
}

.deliveryoption__name {
  color: var(--neutral-70);
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
}

.deliveryoption__price {
  margin-left: auto;
}

.deliveryoptions__single.pr_radiogroup.pr_radiogroup--boxed .pr_input__radio.is-checked {
  background-color: transparent;
  border-color: var(--neutral-30);

  .pr_input__mark,
  input[type='radio'] {
    display: none;
  }
}

.deliveryoptions__radiogroup.is-disabled {
  pointer-events: none;
}
