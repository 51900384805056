@import 'styles/_sass-includes.scss';

.afterpay__cta {
  text-align: center;
  margin-top: -20px;
  white-space: nowrap;
}

.AfterpayMessage {
  color: var(--neutral-70);
  // media queries
  @include breakpoint($mobile-lg) {
    font-size: 14px;
  }
}
