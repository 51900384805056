@import 'styles/_sass-includes.scss';

.support__contactsuccess {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  padding: 100px 0;
  // media queries
  @include breakpoint($mobile-lg) {
    padding: 20px 0 50px;
  }
}

.support__contactsuccess__btn {
  display: inline-flex;
  align-items: center;
  color: $black;
  font-size: 24px;

  .pr_icon {
    margin-left: 10px;
  }
}
