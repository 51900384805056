@import 'styles/_sass-includes.scss';

.private-listing {
  .pr_alert__container {
    background: #2F80ED;
    border-left: none;
    width: 348px;
    border-radius: 4px;
    font-family: Poppins;
  
    .listing__content {
      display: flex;
      color: $white;
      align-items: center;
  
      .pr_icon {
        margin-right: 15px;
  
        svg {
          stroke-width: 1.3;
        }
      }
    
      p:first-of-type {
        font-weight: 500;
        font-size: 14px;
        line-height: 157.1%;
        opacity: 1;
      }
    
      p {
        margin-bottom: 0;
        font-size: 12px;
        opacity: 0.6;
      }
    }
  }
}
