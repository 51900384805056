@import 'styles/_sass-includes.scss';

.payment-page {
  & .pr_inputfield:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  & .stripe-checkout-container {
    padding: 13px 12px;
    border-color: #EAEAEA;
    background-color: var(--neutral-00);
    border-color: var(--neutral-20);
    border-radius: 8px;
    height: 50px;
  }

  & .card-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-top: 8px;

    & * {
      flex: 1;
    }
  }

  .stripe-checkout-container--error {
    border-color: var(--negative);
  }

  .pr_alert__container {
    margin-bottom: 25px;

    p {
      color: $black;
    }

    .payment-error {
      .payment-error__title {
        font-weight: bold;
      }
    }
  }

  .afterpay-container .pr_input__radio {
    display: block;
  }

  button[type=submit] {
    width: 100%;
    border-radius: 16px;
  }

  button[type=submit].btn-afterpay {
    display: flex;
    justify-content: space-around;
    height: 55px;
    background-color: $black;
    border-color: $black;
    width: 100%;
    color: #B2FCE4;
    overflow: hidden;
    &.btn--disabled {
      opacity: 0.4;
      background-color: $black !important;
    }
    img {
      height: 55px;
      @include breakpoint ($mobile-md) {
        max-width: none;
        height: 50px;
      }
    }
  }

  .btn--legacy-shipping {
    color: $blue;
    font-size: 14px;
    cursor: pointer;
  }

  .delivery__fallback {
    background-color: $mediumgrey;
    border-radius: 6px;
    color: $darkgrey;
    font-weight: 600;
    opacity: 0.5;
  }

  .disabled-opacity {
    opacity: 0.4;
    pointer-events: none;
  }
}

.payment-page__heading {
  margin-bottom: 26px;
}

.paypal-buttons-disabled {
  opacity: 1 !important;
}
