@import 'styles/_sass-includes.scss';

.product-feed{
  padding: 1.5rem 3em 0;
  max-width: 136.3rem;
  margin: 0 auto;
  width: 100%;

  @include breakpoint($mobile-lg) {
    padding: 1.5rem 1.5rem 0;
  }

  .product-feed-skeleton-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    width: 100%;
    height: 350px;
    flex-wrap: wrap;

    .product-tile-skeleton {
      width: calc(25% - 1.5rem);
      height: calc(80% - 1.5rem);

      @include breakpoint($mobile-lg) {
        width: 45%;
      }
    }
  }
}

.tile-heading {
  color: var(--neutral-100);
}

.grid-container {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 16px; /* This sets both row-gap and column-gap */
  /* Adjust the minmax() values as needed */
}

@media (max-width: 768px) { /* Adjust this breakpoint as needed */
  .grid-container {
    grid-template-columns: 1fr; /* Makes the grid single-column on small screens */
  }
}

.grid-span-1 {
  grid-column: span 1 / span 1;
}

.grid-span-2 {
  grid-column: span 2 / span 2;
}

.grid-span-3 {
  grid-column: span 3 / span 3;
}

.grid-span-4 {
  grid-column: span 4 / span 4;
}

.grid-span-5 {
  grid-column: span 5 / span 5;
}

.grid-span-6 {
  grid-column: span 6 / span 6;
}

.grid-span-7 {
  grid-column: span 7 / span 7;
}

.grid-span-8 {
  grid-column: span 8 / span 8;
}

.grid-span-9 {
  grid-column: span 9 / span 9;
}

.grid-span-10 {
  grid-column: span 10 / span 10;
}

.grid-span-11 {
  grid-column: span 11 / span 11;
}

.grid-span-12 {
  grid-column: span 12 / span 12;
}

.grid-row-span-2 {
  grid-row: span 2 / span 2;
}

@media (max-width: 768px) {
  .grid-item {
    grid-column: auto; /* Resetting the span on small screens */
  }
}

.custom-grid .product-tile.square {
  width: 100%;
  margin: 0 0.75rem 3rem;
}

.product-tile.square .product-tile-price {
    opacity: 0.75;
}

@media (max-width: 768px) {
  .custom-grid .product-tile.square {
    margin: 0 0 3rem;
  }
}