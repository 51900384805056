@import 'styles/_sass-includes.scss';

.btn.legacycheckoutbtn {
  background-color: #F2F2F2 !important; // Overrides theme bg color
  color: $black !important; // Overrides theme bg color
  padding: 0 16px;
  font-size: 14px;
  height: 31px;
  cursor: pointer;
  border: 0;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 36px;
}
