@import 'styles/_sass-includes.scss';

.promocode__link {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--neutral-20);
  color: var(--neutral-70);
  font-weight: 500;
  border-radius: 16px;
  height: 50px;
  padding-left: 18px;
  padding-right: 28px;
  font-size: 16px;
  cursor: pointer;
  // media queries
  @include breakpoint($mobile-lg) {
    width: 100%;
  }

  &:hover {
    background-color: var(--neutral-05);
    border-color: var(--neutral-70);
  }

  .pr_icon {
    margin-right: 10px;
  }
}

.pr_form__label {
  display: none;
}

.checkoutpage .promocode__submit.pr_btn {
  padding: 0 20px;
  background-color: $white;
  flex-grow: 0;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 0;
  border-color: var(--neutral-20);
  color: var(--primary-ui);
  height: 50px;

  &.btn--disabled {
    background-color: $white;
    border-color: #DDD;
  }
}

.promocode {
  margin-top: 24px;

  .pr_inputfield {
    margin-bottom: 0;
    flex-grow: 1;
    background: $white;
    width: 100%;

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
  }
}

.promocode__fields {
  display: flex;
  align-items: center;

  &.is-focused .promocode__submit.pr_btn {
    border-color: var(--neutral-70);
  }

  &.has-error {
    .promocode__submit.pr_btn,
    .pr_inputfield input {
      border-color: var(--negative);
    }
  }
}

.promocode__active__rm {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    color: $black;
    height: 24px;
    line-height: initial;
    cursor: pointer;
  }
}

.pr_btn.btn--secondary.promocode__change {
  margin-top: 16px;
  background-color: transparent;
}

.promocode__active {
  display: flex;
  background-color: var(--neutral-10);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  .close {
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: bold;

    &:hover {
      color: $red;
    }
  }

  p {
    flex-grow: 1;
  }
}
