@import 'styles/_sass-includes.scss';

.pagination {
  width: 100%;
}

.pagination__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem 0;

  button {
    display: block;
    margin: 0 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.is-disabled {
      pointer-events: none;
      opacity: 0.2;
    }

    &.is-active {
      font-weight: bold;
      text-decoration: underline;
    }

    &:hover,
    &:focus,
    &:active {
      color: #222;
    }

    &.pagination__prev,
    &.pagination__next {
      width: auto;
    }

    &.pagination__prev { margin-left: 0; }
    &.pagination__next { margin-right: 0; }
  }
}
