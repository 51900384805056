@import "~styles/sass-includes";

.login-page {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;

    .header {
        height: 75px;
    }

    .login-wrapper {
        width: 400px;
        height: 400px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        overflow: auto;
        display: table;
    }

    .footer {
        height: 200px;
        position: absolute;
        bottom: 0;
    }
}
