@import '~styles/sass-includes';

.listing-gallery {
  display: flex;
  justify-content: flex-start;
  position: relative;
  // media queries
  @media only screen and (max-width: $tablet-lg) and (min-width: $tablet-sm) {
    flex-direction: column;
  }
  @include breakpoint($mobile-lg) {
    margin-bottom: 60px;
    display: block;
  }
}

.listing-gallery-viewport {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 200%;
  background-origin: center center;
  border-radius: 15px;
  border: 1px solid $neutral20;
  // media queries
  @include breakpoint($mobile-lg) {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }

  &.zoom-active:hover {
    @include breakpoint($tablet-sm) {
      cursor: zoom-in;
    }

    img {
      @include breakpoint($tablet-sm, min-width) {
        opacity: 0;
      }
    }
  }

  img {
    display: block;
    // media queries
    @include breakpoint($mobile-lg) {
      min-width: 100%;
    }
  }
}

.gallery__loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba($white, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  // media queries
  @include breakpoint($mobile-lg) {
    height: calc(100% + 45px);
    width: 100vw;
    margin-left: -4rem;
  }
  @include breakpoint($mobile-md) {
    margin-left: -2.5rem;
  }
}

.listing-gallery__next-img {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(-1rem) translateY(calc(-50% + 1rem));
  padding: .5rem 0;
  display: none;
}
