@import 'styles/_sass-includes.scss';

.track-order {
  font-family: Poppins;

  .support {
    text-decoration: underline;
    color: $darkgrey;
    font-size: 14px;
  }

  .pr_breadcrumbs {
    margin-left: 20rem;
    font-size: 14px;

    @include breakpoint($mobile-md) {
      margin-left: 0;
    }
  }

  .order-tracking {
    text-align: center;
    margin: 16px auto;
    width: 47rem;

    @include breakpoint($mobile-md) {
      width: 35rem;
      text-align: left;
      width: 100%;
      margin: 0 auto 5rem;
    }

    h5, p, a {
      font-size: 14px;
    }

    h1, h5 {
      font-family: inherit;
      text-transform: inherit;
      color: $black;
    }

    h1 {
      font-weight: 500;
      font-size: 36px;
      line-height: 54px;

      @include breakpoint($mobile-md) {
        font-size: 24px;
      }
    }

    h5 {
      font-weight: 600;
      line-height: 14px;
    }

    a {
      text-decoration: underline;
      color: $darkgrey;
    }

    .order-number {
      text-align: left;
      width: 46rem;
      margin: auto;

      @include breakpoint($mobile-md) {
        width: auto;
      }

      a {
        color: $black;
        font-style: normal;
        font-weight: 400;
      }

      .pr_btn {
        text-decoration: none;
        width: 100%;
        border: none;
      }

      p {
        width: 100%;

        @include breakpoint($mobile-md) {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
