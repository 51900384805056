@import 'styles/_sass-includes.scss';

.pr_modal__container.quick__add__modal {
  align-items: flex-start;
  justify-content: flex-end;
  // media queries
  @include breakpoint($mobile-lg) {
    justify-content: center;
  }

  .pr_modal__overlay {
    @include breakpoint ($mobile-lg, min-width) {
      background-color: transparent;
    }
  }

  .pr_modal__close {
    @include breakpoint ($mobile-lg, min-width) {
      display: none;
    }
  }

  .pr_modal__content {
    max-width: 463px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0 16px 16px;
    // media queries
    @include breakpoint ($mobile-lg, min-width) {
      margin-right: 27px;
    }
    @include breakpoint($mobile-lg) {
      padding: 0;
    }
  }

  .quick__add__info {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 16px 0px;

    &:after {
      content: '';
      position: absolute;
      width: calc(100% + 32px);
      margin-left: -16px;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: var(--neutral-10);
    }

    .quick__add__notification {
      display: flex;
      align-items: center;
      font-family: $inter-font-family;
      font-weight: 500;
      font-size: 16px;
      color: var(--neutral-100);

      .icon {
        margin-right: 8px;
      }
    }
  }

  .quick__add__cartitems {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 45rem);
    width: calc(100% + 32px);
    margin-left: -16px;
    padding: 0 16px 16px;
    // media queries
    @include breakpoint($mobile-md) {
      max-height: calc(100vh - 40rem);
    }
    @media only screen and (max-height: 500px) {
      max-height: none;
    }
  }

  .quick__add__item {
    display: flex;
    position: relative;

    @include breakpoint($mobile-lg) {
      flex-direction: row;
      justify-content: space-between;
    }

    &:not(:last-of-type) {
      margin-bottom: 2rem;
    }

    img {
      width: 80px;
      height: 80px;
      align-self: flex-start;
      border-radius: 8px;

      @include breakpoint($mobile-lg) {
        border: 0.419162px solid $neutral20;
      }
    }
  }

  .quick__add__item__info {
    width: 100%;
    flex-grow: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: $inter-font-family;

    @include breakpoint($mobile-lg) {
      max-width: none;
      padding-right: 0;
    }

    span {
      font-weight: bold;
    }

    .quick__add__item__title__and__description {
      margin-right: 16px;
      color: var(--neutral-100);

      p:not(.quick__add__item__title) {
        color: var(--neutral-70);
        font-size: 14px;
      }
    }
  }

  .quick__add__item__title {
    width: 100%;
    margin-bottom: 0.5rem;
    line-height: 1.5;

    font-family: $inter-font-family;
    font-weight: 500;
    font-size: 16px;
    font-style: normal;

    @include breakpoint($mobile-lg) {
      width: unset;

      span {
        font-weight: 500;
      }
    }
  }

  .quick__add__item__price {
    color: var(--neutral-100);
    font-size: 14px;
    font-weight: 400;
  }

  .quick__add__checkout__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    a {
      border: 1px solid var(--neutral-20);
      padding: 10px 24px;
      height: 50px;
      border-radius: 16px;
      width: 209px;
      height: 50px !important;
      text-align: center;

      @include breakpoint($mobile-lg) {
        margin-right: 12px;
      }
    }

    button {
      background-color: var(--blue) !important;
      border-color: var(--blue) !important;
      border-radius: 16px;
      padding: 10px 24px;
      height: 50px;
      width: 209px;
      justify-content: center;
    }
  }

  &.top .pr_modal__inner {
    @include breakpoint($mobile-lg) {
      padding: 0 16px 16px;
    }
  }
}
