@import 'styles/_sass-includes.scss';

.support__contact {
  max-width: 600px;
  padding-bottom: 100px;
  // media queries
  @include breakpoint($tablet-md) {
    padding-bottom: 0;
  }
}
