@import 'styles/_sass-includes.scss';

.document {
  padding-top: 60px;
  padding-bottom: 60px;
  // media queries
  @include breakpoint($mobile-lg) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &.row {
    max-width: 1160px;
  }

  h5 {
    margin-top: 30px;
  }

  ul {
    margin-bottom: 10px;

    li {
      list-style-type: disc;
      margin-left: 18px;
    }
  }
}
