@import 'styles/_sass-includes.scss';

.collection-content {
  text-align: left;
  padding: 1.5rem 3em 0;
  max-width: 136.3rem;
  width: 100%;

  margin: 0 auto;
  margin-top: 3em;

  // media queries
  @include breakpoint($mobile-lg) {
    padding: 1.5rem 1.5rem 0;
  }

  .tile-heading{
    padding: 1rem 0 2rem;
  }
}