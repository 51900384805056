@import 'styles/_sass-includes.scss';

.bs__notifications {
  .pr_toaster-container {
    z-index: 9999999999;
    position: fixed;
    top: 10px;

    @include breakpoint($mobile-md) {
      right: 10px;
    }
  }
}