@import 'styles/_sass-includes.scss';

.promocode_container {
  height: 57px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #08080d;
  z-index: 1;
}