@import '~styles/sass-includes';

.pr_modal__inner{
  height: 100%;
  display: flex;
  flex-direction: column;
  @include breakpoint($mobile-lg) {
    position: relative;
  }
}

.pr_modal__content{
  display: flex;
}

.pr_modal__content_wrapper{
  width: 100%;
}

.pr_modal__container.cartconfirm {
  align-items: flex-start;
  font-family: $inter-font-family;
  justify-content: flex-end;

  .relatedgrid_container{
    height: 23vh;
    min-height: 21em;
    position: relative;
    margin: 2em 0 1em 0;

    h3{
      font-size: 18px;
    }

    .pr_carousel__slide{
      width: 35% !important;
      padding-right: 0;
    }
    &::before{
      content: '';
      height: 100%;
      width: 100em;
      background-color: #F8F8F8;
      display: block;
      left: -10em;
      position: absolute;
      top: -1.7em;
      z-index: -1;
      border: solid 1px #B8BDC5;
    }

    .product-tile-details{
      flex-direction: column;

      .product-tile-info{
        margin-bottom: .5em;
      }
    }

    @include breakpoint($mobile-lg) {
      margin: 1em 0 2em 0;
    }
  }

  .cartconfirm__closeicon {
    position: absolute;
    top: 20px;

    @include breakpoint($mobile-lg) {
      top: 0px;
      right: 20px;
      border: 1px solid var(--neutral-20);
      border-radius: 16px;
    }

    svg {
      @include breakpoint($mobile-lg) {
        padding: 10px;
      }
    }
  }

  .modal__header {
    font-family: $inter-font-family;
    font-size: 16px;
    margin-bottom: 0px;
    padding-bottom: 22px;
    text-transform: capitalize;

    @include breakpoint($mobile-lg) {
      padding: 20px 0px 30px;
    }
  }

  .pr_modal__content {
    margin-right: 44px;
    border-radius: 0px 0px 8px 8px;
    overflow-x: hidden;
    max-height: 100vh;

    // media queries
    @include breakpoint($mobile-lg) {
      overflow: hidden;
      bottom: 0;
      position: absolute;
      margin: 0px;
      max-width: none;
      width: 100%;
      border-radius: 8px 8px 0px 0px;
      max-height: 100%;
      max-height: 100svh;

      &[style] {
        top: auto !important;
      }
    }
  }

  .cart__pill {
    position: absolute;
    top: 12px;
    right: 8px;
  }
}

.cartconfirm__buttons-section {
  display: flex;
  flex-direction: column;

  @include breakpoint($mobile-lg) {
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.45);
    bottom: 0;
    background-color: $white;
    position: fixed;
    padding-bottom: 16px;
    margin-left: -16px;
    width: 100vw;
  }

  * {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}

.cartconfirm__buttons {
  display: flex;
  gap: 12px;
  padding-bottom: 16px;
  // media queries
  @include breakpoint($mobile-lg) {
    padding: 16px;
    flex-direction: row-reverse;
  }
}

.cartconfirm__refund_policy_text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $darkergrey;
  font-size: 14px;

  .cartconfirm__refund_policy_icon {
    margin-right: 4px;
  }

  .cartconfirm__refund_policy_link {
    color: var(--neutral-70);
    margin-left: 8px;
    text-decoration: underline;
  }
}

.cartconfirm__cartitems {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 40vh;
  width: calc(100% + 5rem);
  margin-left: -2.5rem;
  margin-top: 1em;
  padding: 0px 2.5rem 2rem;
  min-height: 15vh;
  // media queries
  @include breakpoint($mobile-lg) {
    width: calc(100% + 3rem);
    margin-bottom: 1em;
    margin-left: -1.5rem;
    padding: 0px 1.5rem 0px;
  }
  @include breakpoint($mobile-md) {
    max-height: calc(100vh - 20rem);
  }
  @media only screen and (max-height: 500px) {
    max-height: none;
  }
}

.cartconfirm__item {
  display: flex;
  justify-content: space-between;
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  img {
    border: 0.4px solid var(--neutral-20);
    border-radius: 8px;
    width: 140px;
    height: auto;
    align-self: flex-start;
    // media queries
    @include breakpoint($mobile-lg) {
      max-width: 80px;
      margin-bottom: 0.5rem;
      top: auto;
      bottom: 3.3rem;
    }
  }
}

.cartconfirm__total {
  border-top: 1px solid rgba($black, 0.15);
  border-bottom: 1px solid rgba($black, 0.15);
  padding: 2rem 0px;
  color: $black;

  div {
    display: flex;
    font-weight: 500;
    justify-content: space-between;
  }

  .cartconfirm__shipping_calculated {
    color: var(--neutral-70);
    font-size: 14px;
    font-weight: 400;
  }
}

.cartconfirm__item__info {
  max-width: 25rem;
  width: 100%;
  flex-grow: 0;
  padding-right: 2rem;
  // media queries
  @include breakpoint($mobile-lg) {
    max-width: none;
    padding-right: 0;
  }

  > p:not(.cartconfirm__item__title) {
    font-size: 14px;
  }
}

.cartconfirm__item__price {
  font-size: 14px;
  color: $black;

  // media  queries
  @include breakpoint($mobile-lg) {
    display: flex;
    justify-content: flex-start;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  span .price {
    margin-top: 0px;
  }
}

.cartconfirm__item__title {
  font-weight: 500;
  width: 100%;
  margin-bottom: 0.5rem;
  line-height: 1.5;
  color: $black;
}

.cartconfirm__afterpay {
  font-size: 14px;
}
