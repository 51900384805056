@import 'styles/_sass-includes.scss';

.product__options {
  margin-bottom: 1em;

  .color__options {
    background: $white;
    border-radius: 16px;
    position: relative;
    margin: -100px 0 30px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .add__options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .pr_dropdown {
      margin-right: 10px;
    }

    .pr_dropdown button {
      height: 50px;
      width: 50%;

      display: flex;
      justify-content: center;

      border-radius: 16px;

      color: $black;
    }

    .pr_inputfield, .pr_selectfield__wrapper {
      margin-bottom: 0;
    }
   }

   .quick__addcart--mobile {
      position: fixed;
      display: flex;
      align-items: flex-end;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 10001;

     .pr_selectfield {
       color: $black;
     }

     .quick__add__base {
      @include breakpoint($mobile-lg) {
        height: 60px;
        width: 100%;
        border-radius: 16px;
        font-size: 16px;
        padding: 0 10px;
        display: flex;
        justify-content: center;
      }
   }
}

  .quick__add__full {
    width: 100%;
    justify-content: center;
  }

  .size__options .pr_selectfield__wrapper {
    margin-bottom: 0;
  }

  .productoptions__title {
    color: var(--neutral-100);
    font-family: $inter-font-family;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  .divider {
    border-bottom: 1px solid var(--neutral-10);
    margin-bottom: 15px;
    margin-left: -25px;
    margin-right: -25px;
  }
}

.stickymenu__header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.stickymenu__close_button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 25px; // account for sticky modal's padding
  height: 50px;
  width: 50px;
  border: 1px solid var(--neutral-20);
  border-radius: 16px;
}

.quick__add__base {
  @media screen and (min-width: $mobile-lg) and (max-width: $desktop-sm){
    padding: 0;
  }
}