@import 'styles/_sass-includes.scss';

.tile-heading {
  text-align: left;
  padding: 3rem 0 2rem;
  margin-left: .6em;
  margin-bottom: 1em;
  // media queries
  @include breakpoint($mobile-lg) {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0;
  }
}

.anchor {
  top: -9rem;
  height: 0;
  overflow: hidden;
  position: relative;
}
