@import 'styles/_sass-includes.scss';

.localizationmodal .pr_modal__content {
  // media queries
  @include breakpoint($mobile-lg, min-width) {
    max-width: 350px;
  }
}

.localizationmodal .pr_modal__contentwrapper {
  width: 100%;
}

.languagecontrols__submit {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  // media queries
  @include breakpoint($mobile-xsm) {
    flex-direction: column;
    margin-top: 25px;
  }

  .pr_btn {
    width: calc(50% - 10px);
    justify-content: center;
    // media queries
    @include breakpoint($mobile-xsm) {
      width: 100%;
    }

    &:nth-of-type(1) {
      // media queries
      @include breakpoint($mobile-xsm) {
        margin-bottom: 10px;
      }
    }
  }
}

.languagecontrols {
  .languagecontrols__region {
    color: $black;
  }
  .languagecontrols__currency {
    color: $black;
  }
}
