@import 'styles/_sass-includes.scss';

.checkout-express {
  display: flex;
  align-items: center;
  height: calc(100vh - 55px);

  .btn-hidden {
    display: none !important;
  }
}
