@import 'styles/_sass-includes.scss';

.qtyfield {
  height: 50px;
  .pr_selectfield__toggle,
  .pr_selectfield {
    color: $black;
    
  }
  .dropdown__menu {
    max-height: none;
  }
  
  .form__input {
    width: 70px;
    padding-left: 10px !important;
    padding-right: 10px;
    display: flex;
    align-items: center;
  }
}

.qtyfield.cart-item-qty {
  min-width: 100%;

  @include breakpoint($mobile-lg) {
    margin-top: 32px;
  }
  .pr_selectfield {
    width: 100%;
  }


}

.qtyfield .pr_inputfield__label--animated {
  z-index: 10;
}