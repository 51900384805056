@import 'styles/_sass-includes.scss';

.pr_modal__container.quick__add__modal__oos {
  align-items: flex-start;
  justify-content: flex-end;
  // media queries
  @include breakpoint($mobile-lg) {
    justify-content: center;
  }
  .pr_modal__overlay {
    @include breakpoint ($mobile-lg, min-width) {
      background-color: transparent;
    }
  }

  .pr_modal__content {
    max-width: 463px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0 16px 16px;
    // media queries
    @include breakpoint ($mobile-lg, min-width) {
      margin-right: 27px;
    }
    @include breakpoint($mobile-lg) {
      padding: 0;
    }
  }
  .pr_modal__contentwrapper{
    padding: 18px 0 2px;
    text-align: center;
    width: 100%;
  }

  .pr_modal__inner {
    color: black;
  }
}
