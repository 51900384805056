@import 'styles/_sass-includes.scss';

.checkout-details__heading {
  margin-bottom: 26px;
}

.checkout-details__form {
  &.is-disabled {
    pointer-events: none;
  }

  .pr_dropdown__toggle {
    height: 50px;
  }

  .pr_inputgroup {
    margin-bottom: 0;
  }

  .pr_inputgroup:last-of-type {
    margin-bottom: 10px;
  }

  .pr_inputfield,
  .pr_selectfield__wrapper {
    margin-bottom: 20px;
  }

  .pr_selectfield__wrapper + .pr_form__error {
    margin-top: -10px;
    margin-bottom: 20px;
  }

  .pr_selectfield option {
    color: $black;
  }

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .pr_btn {
    margin-top: 22px;
  }
}

.pr_asm__wrapper strong {
  margin-bottom: 8px;
  display: block;
}

.pr_address-suggestion-modal .pr_asm__suggestion,
.pr_address-suggestion-modal .pr_asm__suggestion__selected {
  font-size: 14px;
}

.pr_address-suggestion-modal h1 {
  font-size: 18px;
  text-transform: none;
  text-align: center;
  border-bottom: 1px solid var(--neutral-20);
  padding-bottom: 22px;
  width: calc(100% + 50px);
  margin: -36px 0 20px -25px;
  // media queries
  @include breakpoint($mobile-lg) {
    width: 100%;
    margin-top: 16px;
    margin: 16px 0 0;
    border: 0;
  }
}

.PhoneInput {
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
  color: inherit;

  &.has-error .PhoneInputInput {
    border-color: var(--red);
  }
}

.PhoneInputInput {
  border: 1px solid #EAEAEA;
  padding: 0 16px;
  height: 50px;
  border-radius: 4px;

  &:focus {
    border-color: var(--neutral-100);
  }
}

.PhoneInputCountry {
  margin-right: 5px;
  padding: 0 16px;
  background-color: $lightgrey;
  height: 50px;
  border-radius: 4px;
}

.form__phoneinput {
  display: flex;
  align-items: center;
  position: relative;

  .pr_inputfield__label {
    font-size: 12px;
    transform: translateY(-45%) translateZ(0px);
    position: absolute;
    top: 0;
    left: 91px;
    padding: 0 4px;
    margin-bottom: 0;
    background-color: $white;
    font-weight: normal;
    line-height: 1.6;
    z-index: 1003;
    color: #757575;
  }

  .pr_icon {
    color: $mediumgrey;
  }

  .pr_icon,
  .pr_tooltip,
  .pr_tooltip__toggle {
    height: 24px;
  }

  .pr_tooltip.align-left .pr_tooltip__content {
    right: calc(100% + 17px);
  }
}

.PhoneInputCountryIcon {
  margin-right: 4px;
}

:root {
  --PhoneInputCountrySelectArrow-borderWidth: 2px;
  --PhoneInputCountrySelectArrow-width: 6px;
  --PhoneInputCountrySelectArrow-color: #404040;
}
