@import 'styles/_sass-includes.scss';

.color__options__wrapper {
  position: relative;
  width: calc(100% - 32px);
  margin-left: 16px;
  margin-top: -100px;
  margin-bottom: 32px;
  height: 68px;
  background-color: white;
  overflow: hidden;
  border-radius: 16px;
}

.quick__addcart--mobile {
  .color__options__wrapper {
    width: 100%;
    margin: 0;
    overflow: visible;
    border-radius: 0;
    background-color: transparent;
    height: auto;
    margin: 0;
    position: static;
  }

  .color__options__scroller {
    height: auto;
    overflow: visible;
  }
}

.color__options__controls {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  pointer-events: none;
  background: transparent;
  z-index: 20;
  height: 100%;
  overflow: hidden;
  border-radius: 16px;
}

.color__options__arrow {
  background-color: var(--neutral-00);
  display: flex;
  align-items: center;
  flex-basis: 32px;
  justify-content: center;
  pointer-events: auto;
  color: var(--neutral-90);

  &.left {
    margin-right: auto;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  }

  &.right {
    margin-left: auto;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
  }
}

.color__options__scroller {
  height: 68px;
  width: 100%;
  overflow-x: auto;
}

.color__options {
  display: flex;
  align-items: center;
  height: 100%;

  .variantselector__grid {
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    margin: 0 auto;
  }
}

.listing__options {
  &.colors {
    margin-bottom: 20px;
  }

  &.sizes {
    margin-bottom: 30px;
  }

  .color__swatch {
    height: 24px;
    width: 24px;

    z-index: 2000;
    border-radius: 50%;
    border: 1px solid var(--neutral-20);

    position: absolute;
    right: calc(25px + 70px);
    transform: translateY(20px);
  }

  .pr_selectfield__toggle {
    @include breakpoint($mobile-lg) {
      height: 62px;
      border-radius: 16px;
    }
  }
}
