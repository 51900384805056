@import 'styles/_sass-includes.scss';

.search-content {
  background-color: $white;
  color: $black;
  font-size: 1.4rem;
  height: calc(100vh - 4.3rem);
  position: relative;
  max-height: 100vh;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;

  .search-results {
    background: $white;
    opacity: 0;
    margin-top: 14rem;
    padding-top: 6rem;
    transition: all 0.5s ease-out 0.2s;
    pointer-events: none;

    > p {
      width: 100%;
      text-align: center;
      color: $black;
      padding-bottom: 20rem;
    }
  }

  &.search-result-active {
    max-height: unset;
    overflow: auto;
    -webit-overflow-scrolling: touch;

    .search-results {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .loader {
    margin-bottom: 5rem;
  }
}

.search-controls {
  padding: 2.7rem;
}

.search-close {
  color: inherit;
  font-weight: bold;
  position: absolute;
  right: 2.7rem;
  top: 2.7rem;
  font-size: 2.7rem;
  // media queries
  @include breakpoint($mobile-lg) {
    right: 2rem;
    top: 1rem;
    font-size: 1.7rem;
  }
}

.search-heading {
  position: absolute;
  top: 24%;
  transition: all 0.4s ease-out;
  z-index: 1;

  .search-result-active & {
    top: 6rem;
  }

  h2 {
    color: $black;
    margin-bottom: 1rem;
  }
}

.search-filters {
  border-bottom: 0.1rem solid $black;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% - 5.4rem);
  transition: all 0.4s ease-out;
  z-index: 1;

  .search-result-active & {
    top: 17rem;
  }
}

.search-bottom-spacer {
  background: $white;
  height: 10rem;
  width: 100%;
}
