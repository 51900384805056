@import '~styles/sass-includes';

.relatedgrid {
  width: 100%;

  h3 {
    font-size: 24px;
    font-weight: 500;
    color: var(--neutral-100);
    text-align: left;
    margin-bottom: 0;
    // media queries
    @include breakpoint($mobile-lg) {
      font-size: 18px;
    }
  }

  .products_carousel_wrapper {
    padding-top: 15px;
  }
}

.relatedgrid__products {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 20px);
  margin-left: -10px;
  // media queries
  @include breakpoint($tablet-lg) {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  @include breakpoint($mobile-lg) {
    flex-wrap: wrap;
    width: calc(100% + 20px);
    margin-left: -10px;
  }

  a {
    flex-basis: calc(25% - 60px);
    margin: 0 30px;
    color: $black;
    // media queries
    @include breakpoint($tablet-lg) {
      margin: 0 20px;
    }
    @include breakpoint($mobile-lg) {
      flex-basis: calc(50% - 20px);
      flex-grow: 0;
      margin: 0 10px 40px
    }
  }

  .product-tile {
    // media queries
    @include breakpoint($tablet-lg) {
      margin-bottom: 0;
    }
  }
}

.relatedgrid__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // media queries
  @include breakpoint($mobile-lg) {
    font-size: 12px;
  }
}

.relatedgrid__img {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    max-height: 100%;
  }
}

.relatedgrid__title {
  font-weight: 500 !important;
}
