@import 'styles/_sass-includes.scss';

.unavailable-items {
  color: $black;

  .unavailable-items__list {
    list-style-type: decimal;

    .unavailable-items__item {
      margin-bottom: 8px;
      margin-left: 1em;
      padding-left: 8px;
    }
  }
}
