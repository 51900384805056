@import 'styles/_sass-includes.scss';

.physical_return__details {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--neutral-10);
  padding: 15px;
  border-radius: 16px;
  border: solid 1px var(--neutral-10);
  margin: 12px 0 32px 0;
  font-size: 14px;
  .shield-icon {
    margin-right: 8px;
  }
  .physical_return__content {
    line-height: 14px;
    strong {
      padding-right: 5px;
      color: $black;
    }
    a {
      display: inline-block;
      text-decoration: underline;
      color: #606060;
    }
  }
}
