@import 'styles/_sass-includes.scss';


.pr_btn.refund-policy-button {
    background-color: $white !important;
    border: solid 1px var(--neutral-10) !important;
    min-width: 100%;
    span{
        width: 100%;
    }
}

.refund-policy-shield {
    color: #01BA61;
    margin-right: 6px;
}

.refund-policy-button-text {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    color: $black !important;
    justify-content: center;
    align-content: space-between;
    font-style: normal;
    min-width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */
    margin: 0 auto;
    text-align: center;
    letter-spacing: -0.018em;
    
    /* greyscale / carbon */
    
}
.refund-policy-link-text {
    margin-left: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-decoration: underline;
    color: #595959 !important;
}