// creates an assortment of classes for each
// item passed into $spacer-array
@for $i from 0 through 10 {
  .m#{$i}  { margin: #{$i}rem; }
  .mt#{$i} { margin-top: #{$i}rem; }
  .mr#{$i} { margin-right: #{$i}rem; }
  .mb#{$i} { margin-bottom: #{$i}rem; }
  .ml#{$i} { margin-left: #{$i}rem; }
  .mx#{$i} { margin-left: #{$i}rem; margin-right: #{$i}rem; }
  .my#{$i} { margin-top: #{$i}rem; margin-bottom: #{$i}rem; }

  .p#{$i}  { padding: #{$i}rem; }
  .pt#{$i} { padding-top: #{$i}rem; }
  .pr#{$i} { padding-right: #{$i}rem; }
  .pb#{$i} { padding-bottom: #{$i}rem; }
  .pl#{$i} { padding-left: #{$i}rem; }
  .px#{$i} { padding-left: #{$i}rem; padding-right: #{$i}rem; }
  .py#{$i} { padding-top: #{$i}rem; padding-bottom: #{$i}rem; }
}
