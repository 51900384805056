.disable-scroll {
  overflow: hidden;
}

// align content left for box model layouts
.layout--left {
  float: left;
}

// align content left for box model layouts
.layout--right {
  float: right;
}

// force position relative, used to constrain absolute children
.layout--relative {
  position: relative;
}

.layout--block {
  display: block;
}

// clearfix class, % defined in extends
.cf {
  @extend %clearfix;
}

.page-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content {
    flex: 1;
    position: relative;
    min-height: 300px;
  }
}

.content > .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pr_modal__container {
  max-width: 100vw;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
