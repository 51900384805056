.form__input {
  height: 44px;
  border: 1px solid #b8bdc5;
  background-color: transparent;
  color: inherit;
  padding: 0 15px;
}

.form__label {
  color: #9A9EA7;
  font-size: 16px;
  display: block;
  margin-bottom: 7px;
  font-weight: bold;
}

.pr_inputfield__label {
  font-size: 14px;
}

.pr_inputfield__description {
  font-size: 14px;
  margin-top: 7px;
  display: block;
  color: var(--neutral-70);
}

.pr_inputfield,
.pr_selectfield__wrapper {
  margin-bottom: 20px;
}

.pr_selectfield__wrapper .pr_selectfield__toggle {
  border-radius: 16px;
}
