.btn {
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 35px;
  border-radius: 16px;
  font-weight: bold;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;

  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.btn.btn--transparent {
  background-color: transparent;
  border: 0;
  color: inherit;
  padding-right: 0;
  padding-left: 0;
}
