@import 'styles/_sass-includes.scss';

.promocode_container {
  height: 57px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #08080d;
}

.promocode_content {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: $white;

  @include breakpoint($mobile-lg) {
    font-size: 12px;
    line-height: 16px;
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* Sliding entrances */
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

.hide_in_mobile{
  @include breakpoint($mobile-lg) {
   display: none;
  }
}

.promocode_button {
  box-sizing: border-box;
  width: auto;
  height: 38px;
  border: 1px solid #ffffff;
  border-radius: 12px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;

  @include breakpoint($mobile-lg) {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.underline{
  text-decoration: underline;
}