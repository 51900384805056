@import 'styles/_sass-includes.scss';

.supportsearch {
  display: flex;
  align-items: center;
  padding-left: 15px;
  border: 1px solid $black;
  background-color: transparent;
  max-width: 500px;
  height: 56px;
  margin: 0 auto 60px;
  // media queries
  @include breakpoint($mobile-lg) {
    margin-bottom: 24px;
    max-width: none;
  }

  .pr_inputfield {
    flex: 1;

    input {
      border: 0;
      width: 100%;
    }
  }
}
