@import 'styles/_sass-includes.scss';

.checkoutcartinfo__modal__toggle {
  @extend %page-margins;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 72px;
  z-index: 10004;
  background-color: var(--neutral-00);
  border-bottom: 1px solid var(--neutral-20);
  box-shadow:
    0px 4px 22px rgba(0, 0, 0, 0.05),
    0px 2.59259px 12.8843px rgba(0, 0, 0, 0.037963),
    0px 1.54074px 7.00741px rgba(0, 0, 0, 0.0303704),
    0px 0.8px 3.575px rgba(0, 0, 0, 0.025),
    0px 0.325926px 1.79259px rgba(0, 0, 0, 0.0196296),
    0px 0.0740741px 0.865741px rgba(0, 0, 0, 0.012037);

  .pr_btn {
    background-color: var(--neutral-00);
    border-color: var(--neutral-20);
    color: var(--primary-ui);
    font-weight: 500;
    border-radius: 8px;
    height: 42px;
    padding-left: 18px;
    padding-right: 18px;
  }
}

.pr_modal__container.bottom.mobile-cart-summary-modal button.pr_modal__close {
  border-top: 1px solid var(--neutral-10);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.pr_modal__container.bottom.mobile-cart-summary-modal .pr_modal__inner {
  padding-top: 72px;
}

.checkoutcartinfo__modal__cartlineitems {
  font-weight: 500;
  color: var(--neutral-100);
}
