@import 'styles/_sass-includes.scss';

.address {
  background-color: var(--neutral-05);
  border-radius: 16px;
  padding: 16px;
  color: var(--neutral-90);
}

.checkout .address__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  h3 {
    font-size: 16px;
    text-transform: none;
  }
}

.address__body {
  p {
    margin-bottom: 0;
  }
}