@import 'styles/_sass-includes.scss';

.categorybanner {
  padding: 0 2.7rem;
  display: flex;
  flex-wrap: wrap;
  margin-left: -9px;
  margin-top: 18px;
  width: calc(100% + 18px);
  // media queries
  @include breakpoint($mobile-lg) {
    width: 100%;
    margin-left: 0;
  }
}

.categorybanner__item {
  width: calc(33.3333% - 18px);
  margin: 0 9px;
  background-color: #D5D5D7;
  padding-bottom: 20%;
  position: relative;
  color: white;
  background-position: top;
  background-size: cover;
  cursor: pointer;
  // media queries
  @include breakpoint($mobile-lg) {
    width: 100%;
    padding-bottom: 40%;
    margin: 0 0 18px;
  }

  h2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    padding: 0 20px;
    // media queries
    @include breakpoint($tablet-lg) {
      font-size: 2.4rem;
    }
  }
}
