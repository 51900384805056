@import 'styles/_sass-includes.scss';

.article__content {
  h1 {
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }

  h2 {
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }

  h3 {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  h4 {
    margin-block-start: 40px;
    margin-block-end: 16px;
  }

  h5 {
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
  }

  h6 {
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
  }
  
  a:not(.wp-block-button__link) {
    color: inherit;
    text-decoration: underline;
  }
}