@import 'styles/_sass-includes.scss';
@import '@springforcreators/propel-ui/dist/styles/_sass-includes.scss';

.cart__pill {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 120px;
  font-size: 14px;
  // media queries
  @include breakpoint($mobile-lg) {
    height: 40px;
    width: 65px;
    padding: 0;
  }

  &.white__bg {
    background-color: var(--pure-white);
    color: var(--neutral-100);
  }

  &.blue__bg {
    border: 1px solid var(--primary-ui);
    background-color: var(--primary-ui);
    color: var(--pure-white);
  }

  &.no_cart_items {
    // media queries
    @include breakpoint($mobile-lg) {
      width: 40px;
    }
  }
}
