@import 'styles/_sass-includes.scss';

.search-input {
  display: flex;
  align-items: center; /* align vertical */
  justify-content: space-between;
}

.search-heading h2 {
  @include breakpoint($mobile-md) {
    font-size: 3rem;
  }
}

.search-box {
  font-size: 5rem;
  width: 100%;
  background: transparent;
  caret-color: $black;
  color: inherit;
  transition: all 0.4s ease-out;

  @include placeholder {
    color: $mediumgrey;
  }

  .search-result-active & {
    font-size: 3.2rem;
  }

  @include breakpoint($mobile-md) {
    font-size: 1.6rem;

    .search-result-active & {
      font-size: 2rem;
    }
  }
}

.search-submit {
  display: block;
  float: right;
  padding: 1rem;
  font-size: 3rem;
  color: inherit;
  // media queries
  @include breakpoint($mobile-md) {
    font-size: 1.8rem;
  }

  img {
    height: 3.6rem;
    transition: all 0.4s ease-out;

    .search-result-active & {
      height: 2.4rem;
    }
  }
}

.auto-complete-container {
  position: absolute;
  top: 100%;
  left: 0;
  border-bottom-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  width: 400px;
  background-color: var(--pure-white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.auto-complete-list-item {
  display: block;

  button {
    display: block;
    width: 100%;
    text-align: left;
    padding: 1rem 2rem;
    cursor: pointer;

    &:active,
    &:hover,
    &:focus {
      background-color: #EEEEEE;
    }
}
}
