@import 'styles/_sass-includes.scss';
@import './_default-wordpress-blocks-sass.scss';

.support.content {
  padding-top: 30px;
  padding-bottom: 100px;
  // media queries
  @include breakpoint($mobile-lg) {
    padding-bottom: 30px;
  }

  > .row {
    display: flex;
    // media queries
    @include breakpoint($tablet-md) {
      flex-direction: column;
    }
  }

  .pr_breadcrumbs {
    a.pr_breadcrumbs__crumb {
      text-decoration: underline;;
    }

    .pr_icon {
      display: none;
    }

    .pr_breadcrumb__item:not(:last-of-type):after {
      content: '/';
      padding: 0 10px;
    }
  }

  .pr_breadcrumbs__crumb:not(.is-active) {
    color: #6A6F72;
  }
}

.support__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  margin-bottom: 72px;
  // media queries
  @include breakpoint($mobile-lg) {
    margin-bottom: 52px;
  }
}

.support__callout {
  text-align: right;
  flex: 1;
}

.support__main {
  flex: 1;
  // media queries
  @include breakpoint($tablet-md) {
    order: -1;
  }
}

.support__aside {
  flex-basis: 309px;
  flex-grow: 0;
  padding-right: 24px;
  border-right: 1px solid $mediumgrey;
  margin-right: 40px;
  // media queries
  @include breakpoint($tablet-md) {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
    margin-top: 80px;
    flex-basis: auto;
  }
}

.support__title {
  margin-bottom: 50px;
  // media queries
  @include breakpoint($mobile-lg) {
    margin-bottom: 24px;
  }
}
