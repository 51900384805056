@import '~styles/sass-includes';

.thankyou {
  background-color: #F6F6F8;
}

.thankyou__order {
  background-color: $white;
  margin: 2rem 0;
  padding: 2rem 3.5rem;
  // media queries
  @include breakpoint($mobile-md) {
    padding: 2rem 4rem;
    width: calc(100% + 8rem);
    margin-left: -4rem;
  }
  @include breakpoint($mobile-sm) {
    width: calc(100% + 5rem);
    margin-left: -2.5rem;
    padding: 2rem 2.5rem;
  }
}
