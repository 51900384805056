@import 'styles/_sass-includes.scss';

.global-notification {
  background: $blue;
  font-size: 1.4rem;
  text-align: center;
  letter-spacing: 0.05em;
  padding: 1.2rem;
  color: $white;
  font-weight: 600;
  line-height: 1.4;
  position: relative;
  z-index: 1000;

  a {
    color: inherit;
    text-decoration: underline;
  }
}
