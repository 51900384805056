// default font scaling
html {
  font-size: 10px !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.typ--caps { text-transform: uppercase; }

.typ--titlecase { text-transform: capitalize; }

.typ--smallcaps {
  text-transform: lowercase;
  font-variant: small-caps;
}

.typ--nowrap {
  white-space: nowrap;
}

// font modifiers

// basic element styles
strong, .typ--bold { font-weight: bold; }
p { margin-bottom: 1rem; }
a { text-decoration: none; }

sup {
  vertical-align: super;
  font-size: smaller;
}

sub {
  font-size: 0.6em;
  padding-left: 0.3rem;
}

blockquote {
  padding-left: 3rem;
  border-left: 0.1rem solid $black;
}

// Text decoration styles
.typ--underline { text-decoration: underline; }
.typ--actionable { cursor: pointer; }

.typ--truncate {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

// font alignment
.typ--center {
  text-align: center;
}

.typ--left {
  text-align: left;
}

.typ--right {
  text-align: right;
}

.typ--bold {
  font-weight: bold;
}

.typ--medium {
  font-weight: medium;
}

.typ--mediumgrey {
  color: #6A6F72;
}
