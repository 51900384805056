@import '~styles/sass-includes';

.fanValidationWrapper {
    position: absolute;
    bottom: 64px;
    left: 0;
    width: 430px;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    height: 37px;
    white-space: nowrap;
    // media queries
    @include breakpoint($desktop-sm) {
      width: auto;
      height: auto;
    }
    @include breakpoint($tablet-md) {
      left: -10px;
    }
    @include breakpoint($mobile-lg) {
      height: auto;
      width: 100%;
      max-width: 280px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 32px;
      white-space: normal;
    }
    @include breakpoint($mobile-xsm) {
        display: none;
    }

    .fanValidationContainer {
        opacity: 0;
        transition: 2s;
        position: relative;
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        padding: 10px 16px;
        background: var(--neutral-00);
        box-shadow: 0px 15px 80px rgba(0, 0, 0, 0.07), 0px 6.26664px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.35045px 17.869px rgba(0, 0, 0, 0.0417275), 0px 1.87823px 10.0172px rgba(0, 0, 0, 0.035), 0px 0.997515px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.415088px 2.21381px rgba(0, 0, 0, 0.0196802);
        border-radius: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--neutral-100);
        margin: 0 auto;
        // media queries
        @include breakpoint($mobile-lg) {
          display: block;
          text-align: center;
        }

        .fanValidationBold {
          font-weight: 600;
        }

        .fanValidationAlarmIcon {
          height: 16px;
          width: 16px;
          margin-right: 10px;
          display: inline-block;
        }
    }
}