@import 'styles/_sass-includes.scss';

.checkout__cartitem {
  font-size: 14px;
  margin-bottom: 24px;

  img {
   width: 72px;
   height: 79px;
   object-fit: cover;
   border: 1px solid var(--neutral-10);
   margin-right: 16px;
   border-radius: 8px;
  }
}

.cartitem__info {
  width: 100%;
}

.checkout__cartitem__info {
  display: flex;
}

.cartitem__qty {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .price {
    font-weight: 500;
    color: var(--neutral-90);
  }
}
