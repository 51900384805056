@import 'styles/_sass-includes.scss';

.afterpay-unavailable-modal .pr_modal__content {
  padding: 37px 60px;
  max-width: 550px;
}

.afterpay-unavailable-modal h3 {
  margin-bottom: 7px;
}

.afterpay-unavailable-modal p {
  font-size: 18px;
  color: $darkgrey;
}