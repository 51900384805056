@import 'styles/_sass-includes.scss';

.about-page {
  padding: 8rem 13rem 0;

  @include breakpoint($mobile-md) {
    padding: 8rem 3rem 0;
  }

  .about-page-text {
    line-height: 2.8rem;
    max-width: 670px;
    text-align: center;
    margin: 0 auto;

    p {
      margin: 3rem 0;
    }

    ol {
      list-style: decimal inside;
    }
  }

  .about-image {
    margin: 10rem auto;
    display: block;
  }

  h1 {
    padding-bottom: 3rem;
  }

  h2 {
    padding-bottom: 2rem;
    text-align: center;
  }
}

.about-page-tiles {
  background: #fff;
  padding: 2rem 0;
}