@import 'styles/_sass-includes.scss';

.cart-summary-wrap {
  font-family: "Inter";
  .afterpay__cta {
    margin-top: 20px;
    padding-top: 1px;
    display: block;
    text-align: left;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -2rem;
      width: calc(100% + 4rem);
      height: 1px;
    }

    .AfterpayMessage {
      margin-bottom: 0 !important;
      // media queries
      @include breakpoint($tablet-md, min-width) {
        white-space: nowrap;
      }
      @include breakpoint($tablet-md) {
        text-align: center;
      }
      @include breakpoint($mobile-md) {
        text-align: left;
      }
    }
  }
  @include breakpoint($tablet-md) {
    border-top: 1px solid var(--neutral-20);
    padding-top: 32px;
  }
}

.cart-summary {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 372px;
  background: var(--pure-white);
  border: 1px solid var(--neutral-20);
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05), 0px 2.59259px 12.8843px rgba(0, 0, 0, 0.037963), 0px 1.54074px 7.00741px rgba(0, 0, 0, 0.0303704), 0px 0.8px 3.575px rgba(0, 0, 0, 0.025), 0px 0.325926px 1.79259px rgba(0, 0, 0, 0.0196296), 0px 0.0740741px 0.865741px rgba(0, 0, 0, 0.012037);
  border-radius: 12px;
  flex: none;
  order: 1;
  flex-grow: 0;
  @include breakpoint($tablet-md) {
    margin-left: 0;
    padding: 16px;
    width: 100%; 
    box-shadow: none;
    margin-top: 0;
    border: none;
  }

}
.cart-summary-rows{
  width: 100%;
  margin-bottom: 24px;
}
.cart-summary-row:first-child {
  margin-bottom: 8px;
}
.cart-summary-action-btns {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cart-summary-row {
  font-size: 14px !important;
  font-family: "Inter";
  display: flex;
  justify-content: space-between;
  align-items:center;
  width: 100%;
  .subtext {
    font-weight: 400;
    color: var(--neutral-70);
  }
  .sub-total {
    color: var(--neutral-100);
    font-weight: 500;
  }
  .price {
    font-size: 14px !important;
    margin-top: 0px;
    color: var(--neutral-100);
    font-weight: 500;
  }
}

.cart-summary-actions {
  // media queries
  @include breakpoint($tablet-md) {
    text-align: center;
  }
  @include breakpoint($mobile-md) {
    text-align: left;
  }
}



.cart-checkout-summary-btn {
  font-weight: 500;
  font-family: "Inter";

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 24px;
  height: 50px;
  border: none !important;
  border-radius: 16px;
  margin-bottom: 12px;
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  // media queries
  @include breakpoint($mobile-md) {
    max-width: none;
  }

  &.is-disabled {
    background-color: var(--neutral-30) !important;
  }
  &.cart-summary-btn-paypal {
    border: 1px solid var(--neutral-20) !important;
    background-color: $white !important;
    &.is-disabled {
      border: 1px solid #BFBFBF !important;
    }
  }
  &.cart-summary-btn-afterpay {
    color: $black !important;
    background-color: #B2FCE4 !important;
    overflow: hidden;
    border: 0;
    padding-top:16px;
    img{
      width: 115px;
    }
    &.is-disabled {
      background-color: var(--neutral-00) !important;
      border: 1px solid #BFBFBF !important;
    }
  }
}

.disabled-btn {
  opacity: 0.4;
}

.cart-summary-label {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #2E2E2E;
  margin:0 0 24px 0;
  @include breakpoint($tablet-md) {
    margin:0 0 16px 16px;

  }

}

.cart-summary-note {
  text-align: center;
  font-size: 14px;
  margin-top: 24px;
  color: var(--neutral-70);
  font-family: "Inter";

  .strong {
    font-weight: 500;
  }
  // media queries
  @include breakpoint($tablet-md) {
    margin: 0;
    margin-bottom: 72px;
  }

  strong {
    font-weight: 700;
  }
}

.checkout__btns--mobile {
  position: fixed;
  display: flex;
  align-items: flex-end;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10001;
}

.stickymenu {
  width: 100%;
  background-color: $white;
  padding: 25px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  z-index: 5;
  position: relative;
}

.stickymenu__overlay {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.5);
  z-index: -1;
}

.checkout__sticky {
  background-color: $white;
  width: 100%;
  z-index: 1000;
  padding: 20px 1.5rem 2.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.14);

  .pr_btn {
    width: 100%;
  }

  .afterpay__cta {
    margin: 0;

    &:before {
      display: none;
    }

    .AfterpayMessage {
      margin-bottom: 10px !important;
    }
  }
}

.altpaybutton {
  padding: 6px;
  background-color: $black !important;
  color: $white !important;
  width: 100%;
  height: 50px;
  border-radius: 16px;
  margin-bottom: 12px;

  &.is-disabled {
    background-color: var(--neutral-00) !important;
    opacity: 0.4;
    border: 1px solid var(--neutral-80) !important;
  }
  .pr_btn {
    pointer-events: none;
  }

  .loaderbutton__text,
  .StripeElement {
    opacity: 0;
    width: 100%;
  }
}
