@import 'styles/_sass-includes.scss';

.sizechart__modal-btn {
  @include breakpoint($mobile-lg) {
    text-align: right;
    margin-top: -10px;
  }

  button {
    color: var(--neutral-70);
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    font-family: "Inter", sans-serif;
  }

  svg {
    vertical-align: sub;
  }
}
