@import 'styles/_sass-includes.scss';

.support__nav__category {
  border-color: $mediumgrey;

  .accordion__title {
    font-weight: normal;
  }

  .accordion__content ul li {
    padding-left: 30px;

    &:before {
      display: none;
    }
  }

  .pr_accordion__icon {
    width: 15px;
    height: 15px;
  }

  ul {
    border-top: 1px solid $mediumgrey;
    padding: 17px 0 0;
  }

  li.is-active { font-weight: bold; }

  a { color: inherit; }
}

.support__nav__category__title {
  display: flex;
  align-items: center;

  .pr_icon {
    margin-right: 14px;
  }
}

.support__sidenav {
  margin-bottom: 40px;
  color: $black;

  .btn--icon {
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
  }
}
