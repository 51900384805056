@import 'styles/_sass-includes.scss';

.legacy.header .main-menu {
  // media queries
  @include breakpoint($tablet-lg) {
    display: none;
  }
}

.main-menu {
  display: inline-block;
  margin-right: 1.3rem;
  position: relative;
}

.header-menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 35px;
}
