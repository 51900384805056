// extends must use % syntax to avoid writing classes to multiple component css files

// defines max grid constraint / centered
%container {
  max-width: $content-width;
  margin-left: auto;
  margin-right: auto;
}

// defines page marges roughly equal to one column
%page-margins {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10rem;
  padding-right: 10rem;
  // media queries
  @include breakpoint($desktop-sm) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @include breakpoint($mobile-lg) {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  @include breakpoint($mobile-sm) {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

// clearfix, for clearing parents with floating children
%clearfix {
  clear: both;

  &:after { clear: both; }

  &:before,
  &:after {
    content: '';
    display: table;
  }
}
