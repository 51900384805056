@import 'styles/_sass-includes.scss';

.sizechart-modal h4 {
  text-align: center;
}

.sizechart__container {
  overflow: scroll;
  max-height: 50vh;
  position: relative;
}

.sizechart__measurements {
  font-size: 13px;
  @include breakpoint($mobile-lg) {
    display: flex;
  }
}

.sizechart__measurement-btn {
  padding: 1rem;
  border: 1px solid #e0e0e0;
  width: 6rem;
  cursor: pointer;
  @include breakpoint($mobile-lg) {
    flex-grow: 1;
  }

  &.active {
    background: black;
    color: white;
    border-color: black;
  }
}

.sizechart__table {
  border-collapse: collapse;
  position: relative;
  width: 100%;
  @include breakpoint($mobile-lg) {
    font-size: 13px;
  }

  th, td {
    padding: 13px 10px;
    text-align: center;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
  }

  th {
    background: white;
    position: sticky;
    top: 0;
    box-shadow: inset 0 1px 0 #e0e0e0, 14px 6px 14px 0px rgba(0, 0, 0, .12);
    font-weight: bold;
  }

  td {
    min-width: 70px;
    box-shadow: inset 0 1px 0 #e0e0e0;
    @include breakpoint($mobile-lg) {
      min-width: inherit;
    }
  
    &:nth-child(1) {
      font-weight: bold;
    }
  }

  tr {
    &:nth-child(even) {
      background-color: rgba(#F6F6F6, .79); 
    }

    &:last-of-type td {
      box-shadow: inset 0 1px 0 #e0e0e0, inset 0 -1px 0 #e0e0e0;
    }
  }
}
