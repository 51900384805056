@import 'styles/_sass-includes.scss';

.cart-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  flex-direction: column;
  @include breakpoint($tablet-md) {
    margin-bottom:0;
    padding:32px 16px 32px 16px;
  }
  &:last-child {
    border-bottom: none;
  }
}

.cart-item-row {
  display:flex;
  flex-direction: row;
  vertical-align: top;
  height: 140px;
  align-items:flex-start;
  justify-content: space-between;
  width: 100%;
  @include breakpoint($tablet-md) {
    height: auto;
  }
}

.cart-item-list {
  margin: 24px 0;
  @include breakpoint($tablet-md) {
    margin:0;
  }
}

.cart-item-img-container {
  width: 140px;
  margin-right: 2rem;
  @include breakpoint($tablet-md) {
    width: 80px;
  }
}

.cart-item-img {
  border: solid 0.5px var(--neutral-20);
  border-radius: 8px;
}

.cart-item-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding-right: 24px;
  @include breakpoint($tablet-md) {
    padding-right: 0;
    align-self: flex-start;
    width: 100%;
  }
}



.cart-item-details {
  display: flex;
  padding-right: 24px;
  flex-direction: column;
  position: relative;
  color: #767676;
  flex: 2;
}

.cart-item__controls {
  display: flex;
  flex-direction: column;
  align-items:flex-end;
  justify-content: space-between;
  flex: 1;
  position: relative;
  min-height: 100%;
  // media queries
  @include breakpoint($desktop-md) {

  }
  @include breakpoint($mobile-lg) {
    padding-left: 0;
  }
}
.cart-item-heading {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.cart-item-title {
  font-style: normal;
  line-height: 24px;
  color:var(--neutral-100);
  max-width: 100%;
  margin-bottom: 12px;
  a {
    font-weight: 500;
    color: #000;
    font-size: 16px !important;

  }
  @include breakpoint($tablet-md) {
    font-size: 1.8rem;
  }
  @include breakpoint($mobile-md) {
    max-width: none;
    flex: 1;
  }
}

.cart-item-product-name {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #6A6F72;
  margin-bottom: 4px;
}

.cart-item-quantity {
  position: relative;
  display: flex;
  flex-direction: column;
  @include breakpoint($mobile-lg) {
    top: 3rem;
  }

  input[type=number] {
    width: 7rem;
    font-size: 1.6rem;
    color: $black;
    border-color: #9A9EA7;
  }

  .qty-field {
    width: 100%;
    padding: 0;
    margin-right: 0px !important;
  }
}
.cart-item-price-label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: var(--neutral-30);
  margin-top: 32px;
}
.cart-item-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  color: $black;
  text-align: right;
  .price {
    font-size: 14px;
  }
  // media queries
  @include breakpoint($desktop-md) {
    margin: 0;
    text-align: right;
  }
  @include breakpoint($mobile-md) {
    flex: 0.25;
  }
}

.cart-item-price--left {
  text-align: left;
}
.cart-item-remove-btn {
  font-family: 'Montserrat';
  font-size: 14px;
  text-align: left;
  align-self: flex-start;
  margin-top: 18px;
}

.cart-item-variation {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 14px;
  color: var(--neutral-70);
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  @include breakpoint($tablet-md) {
    height: auto;
  }
}

.cart-item-remove button {
  cursor: pointer;
}
