@import 'styles/_sass-includes.scss';

.different_payment_container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .different_payment_btn {
    background: #f6f6f9 !important;
    padding: 6px 18px;
    width: auto;
    white-space: nowrap;
    height: 50px !important;
    color: black !important;
    border: none !important;
    font-size: 15px;
    line-height: 17px;
    font-style: normal;
    font-weight: 400;
  }

  .different_payment_options {
    padding-left: 10px;

    img {
      width: 187px;
    }
  }
}

.checkout__paymentinfo__item {
  padding: 20px 0 10px;
  position: relative;

  &:not(:first-of-type) {
    border-top: 1px solid #EBEBEB;
  }

  .pr_input__radio {
    width: 100%;
    font-weight: bold;

    input,
    .pr_input__mark {
      position: absolute;
      left: auto;
      right: 0;
      margin-right: 0;
    }
  }
}

.checkout__paymentinfo__item__icon {
  height: 30px;
  position: absolute;
  right: 0;
  pointer-events: none;
  // media queries
  @include breakpoint($mobile-md) {
    height: 24px;
  }
}

.afterpay-container .checkout__paymentinfo__item__icon {
  top: 22px;
  // media queries
  @include breakpoint($mobile-md) {
    top: 26px;
  }
}

.paymentoption__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  position: relative;
  width: 100%;

  > span {
    font-weight: 500;
    color: var(--neutral-100);
  }
}

.paymentoption__inner {
  flex: 1;
  position: relative;

  .AfterpayMessage {
    // important tag necessary to override afterpay plugin styles
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.btn--payment-card {
  border-radius: 4px;
  height: 55px;
}

.paymentoption__inner__card {
  margin: 8px 0 0 -32px;
  width: calc(100% + 32px);

  .pr_inputfield,
  .pr_selectfield__wrapper {
    margin-bottom: 0;
    margin-top: 8px;
  }

  .pr_inputfield__area {
    width: 100%;

    .pr_form__input {
      background-color: var(--neutral-00);
      height: 50px;
      width: 100%;
      border-radius: 8px;
    }
  }
}

.paymentoptions__radiogroup.pr_radiogroup.pr_radiogroup--boxed .pr_input__radio .pr_input__mark{
  top: 21px;
}

.pr_input__radio.afterpay .pr__input__radio__content {
  margin-top: -4px;
  padding-left: 29px;
}

.paymentform__disclaimer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--neutral-70);

  .pr_icon {
    margin-right: 8px;
  }
}

.promocode__mobilecta {
  height: 50px;
  border-radius: 8px;
  background-color: var(--neutral-10);
  width: 100%;
  margin: 24px 0 12px;
  text-align: left;
  padding: 0 16px;
}
