.notification-promo-container.hidden {
  transform: translate3d(0, -100%, 0);
}
.notification-promo-container {
  transition: transform 0.4s;
  height: auto;
  position: sticky !important;
  top: 0;
  z-index: 100;
  width: 100%;
}