@import 'styles/_sass-includes.scss';

.checkout__cartinfo__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.checkout__cartinfo__lineitem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1;

  &.discount {
    color: var(--positive);
  }

  &.hide {
    display: none;
  }
}

.checkout__cartinfo {
  // media queries
  @include breakpoint($tablet-lg, min-width) {
    position: sticky;
    top: 0;
    padding-top: 24px;
  }
}

@include breakpoint($mobile-lg) {
  .checkout__cartinfo--accordion .pr_accordion__title {
    height: auto;
  }

  .checkout__cartinfo--accordion__title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-right: 25px;
  }
}

.checkout__cartinfo__cartitems {
  border-bottom: 1px solid var(--neutral-30);
  margin-bottom: 24px;
}

.checkout__cartitem__info {
  color: var(--neutral-70);
}

.checkout__cartitem__title {
  font-weight: 500;
  color: var(--neutral-90);
  margin-bottom: 8px;
}

.checkout__cartinfo__total {
  font-size: 16px;
  font-weight: 500;
  color: var(--neutral-100);
}

.checkout__cartinfo__inner.checkoutsection {
  padding-top: 24px;
  padding-bottom: 24px;
  border-color: transparent;
  color: var(--neutral-70);
  // media queries
  @include breakpoint($tablet-sm) {
    padding-top: 0;
  }
}

.pr_modal__container .checkoutsection {
  width: 100%;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
}

.pr_modal__container .checkout__cartinfo {
  padding: 24px 8px 0;
}

.cartitem__line:not(:last-child):after {
  content: ', ';
}
