@import 'styles/_sass-includes.scss';

.shopping-cart-content {
  font-family: "Inter";

  width: 1000px;
  margin: 0 auto;
  @include breakpoint($tablet-md) {
    width: auto;
    margin: 0 0;
  }
}

.cart__empty {
  padding: 16px 0;
  @include breakpoint($tablet-md) {
    padding: 24px 16px 72px;
  }
}

.shopping-cart-mobile-header {
  font-family: "Inter";
  display: flex;
  height: 72px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid var(--neutral-10);


  width: 100%;
  padding: 0 16px;
}

.shopping-cart {
  display: flex;
  margin-top: 40px;
  font-family: "Inter";
  @include breakpoint($tablet-md) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 24px;
    min-height: calc(100vh - 30.2rem);
  }
  @include breakpoint($mobile-lg) {
    min-height: calc(100vh - 25.2rem);
  }
}

.shoppingcart__listitems {
  flex: 1;
  margin-right: 24px;
  @include breakpoint($tablet-md) {
    margin-right: 0;
  }
}
.shoppingcart__summary_wrap {
  .price {
    margin-top: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: right;
  
    color: #000000;
  }
  font-family: "Inter";
  min-height: 600px;
  width: 420px;
  height: auto;
  @include breakpoint($tablet-md) {
    min-height: auto;
    width: 100%;
  }

}

.shoppingcart__summary {
  font-family: "Inter";
  flex: 1;
  flex-grow: 0;
  flex-basis: 380px;
  position: sticky;
  top: 0;
  @include breakpoint($tablet-md) {
    position: relative;
    flex-basis: 100%;
  }
}

.shopping-cart-heading {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #000000;
  @include breakpoint($tablet-md) {
    font-size: 18px;;
  }
}

.shopping-cart + .footer {
  @include breakpoint($tablet-md) {
    margin-bottom: 12rem;
  }
}

.related-grid-cart-summary{
  @include breakpoint($mobile-lg) {
    padding: 16px;
  }
}