@import 'styles/_sass-includes.scss';

.socialicons {
  display: flex;
  align-items: center;

  li {
    margin: 12px;
    height: 18px;

    &:first-of-type { margin-left: 0; }
    &:last-of-type { margin-right: 0; }
  }

  a {
    color: inherit;
  }
}
