@import 'styles/_sass-includes.scss';

.support.content {
  color: #6A6F72;

  h1, h2, h3, h4, h5, h6 {
    color: $black;
  }
}

.support__title {
  margin-bottom: 50px;
  // media queries
  @include breakpoint($mobile-lg) {
    margin-bottom: 24px;
  }
}

.support__actions {
  display: flex;
  border: 1px solid #D8DAE0;
  padding: 40px 36px 0;
  width: 100%;
  margin-bottom: 50px;
  flex-wrap: wrap;
  // media queries
  @include breakpoint($mobile-lg) {
    flex-direction: column;
    border: 0;
    padding: 0;
    margin-bottom: 24px;
  }

  > div {
    flex: 1;
    flex-basis: calc(25% - 20px);
    flex-grow: 0;
    margin-bottom: 40px;
    // media queries
    @include breakpoint($mobile-lg) {
      margin-bottom: 8px;
      padding: 24px;
      border: 1px solid #D8DAE0;
      flex-basis: auto;
      flex-grow: initial;
    }
  }

  > div:not(:last-of-type) {
    margin-right: 20px;
    // media queries
    @include breakpoint($mobile-lg) {
      margin-right: 0;
    }
  }
}

.support__action__title {
  display: flex;
  align-items: center;
  font-size: 16px;

  .pr_icon { margin-right: 10px; }
}

.support__action__items {
  margin-top: 15px;

  li a {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: 14px;
    margin-top: 10px;

    &:hover {
      text-decoration: underline;
    }
  }

  .icon {
    margin-right: 7px;
    margin-left: 2px;
  }
}

.support__footer {
  display: flex;
  // media queries
  @include breakpoint($tablet-md) {
    flex-direction: column;
  }

  > div {
    flex: 1;

    &:first-of-type {
      padding-left: 36px;
      // media queries
      @include breakpoint($tablet-md) {
        padding: 0;
        margin-bottom: 38px;
      }
    }

    &:last-of-type {
      padding-right: 36px;
      // media queries
      @include breakpoint($tablet-md) {
        padding: 0;
      }
    }
  }

  .pr_btn {
    // media queries
    @include breakpoint($mobile-lg) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

.btn--icon:not(.loaderbutton) {
  display: flex;
  align-items: center;
  white-space: nowrap;

  .pr_icon {
    margin-left: 10px;
  }
}

.support__footer__btngroup {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  // media queries
  @include breakpoint($mobile-lg) {
    flex-direction: column;
    text-align: center;
    margin-bottom: 24px;
  }

  .pr_btn {
    margin-right: 20px;
    // media queries
    @include breakpoint($mobile-lg) {
      margin-right: 0;
    }
  }
}

.support__callout a {
  color: inherit;
}
