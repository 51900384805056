@import url("https://use.typekit.net/wzg8tjf.css");
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/Montserrat_regular.woff2) format('woff2'),
    url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/Montserrat_regular.woff) format('woff');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/Montserrat_medium.woff2) format('woff2'),
         url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/Montserrat_medium.woff) format('woff');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/Montserrat_demibold.woff2) format('woff2'),
         url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/Montserrat_demibold.woff) format('woff');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/Montserrat_bold.woff2) format('woff2'),
         url(https://premium-storefronts.s3.amazonaws.com/storefronts/shopbetrapp/fonts/Montserrat_bold.woff) format('woff');
}

:root {
    --body-font-family: 'Montserrat', -apple-system, "Helvetica Neue", Roboto, Helvetica, Arial, sans-serif !important;
    --blue: #000 !important;
    --heading-font-family: 'Montserrat', -apple-system, "Helvetica Neue", Roboto, Helvetica, Arial, sans-serif !important;
}

body {
    background-color: #f6f6f8 !important;
    font-family: 'Montserrat', sans-serif !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, input, textarea {
    font-family: 'Montserrat', sans-serif !important;
}

.cart-item-info .cart-item-remove-btn {
    color: #acacac;
}

.relatedgrid h3, .shopping-cart-heading, .listing-header__title, .products_carousel h3 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 700;
}

.listing-details p,.color-label, .listing-details .form__label, .pr_tabs__item span, .product-detail-tabs__content, .product-tile-title, .footer__menu a, .footer__bottomright, .footer__copyright a {
    font-size: 1.4rem;
}

h1, h2, h3, h4, h5, h6, .heading-xl, h1, .heading-l, h2, .heading-m, h3, .heading-s, h4, .heading-xs, h5 {
    text-transform: uppercase;
}

.listing-header p {
    font-size: 1.6rem;
    font-weight: 500;
    color: #333333;
}

.sizechart__modal-btn button {
    text-decoration: none;
    color: #000;
}

.listing-details .form__label {
    color:#000;
    font-weight: 700;
}

.listing-details .listing-header__price .price {
    font-weight: 700;
    color: #333333;
}

.products_carousel_wrapper::after, .products_carousel_wrapper::before {
    display: none;
}

.listing-details {
    border: none;
}

.physical_return__details {
    border: none !important;
    border-radius: 8px;
}

.pr_tabs {
    border-top: initial !important;
    border-bottom: 1px solid rgba(217, 217, 217, 0.4) !important;
}

.pr_tabs__item.is-active span:after {
    background-color: $pdpRed !important;
}

.product-detail-tabs .pr_tabs__item {
    margin-bottom: -4px;
}

.variantselector__grid.size .variantselector__item, .variantselector__grid.size {
    border-radius: 2.4px !important;
    display: flex;
    gap: 8px;
    border: none;

    .variantselector__label {
        font-weight: 600;
        font-size: 1.4rem;
        color: #000;
    }
}

.variantselector__grid.size .variantselector__item {
    height: 40px;
    width: 48px;
    border: solid 1px #000;
}

.variantselector__item.is-active .variantselector__size {
    font-weight: 600;
    height: 48px;
}

.variantselector__grid.size.underfill .variantselector__item {
    border-top: none;
}

.pr_selectfield__toggle {
    border: 1px solid #3c3c3c !important;
}

.checkoutsection .pr_selectfield__toggle {
    color: initial !important;
}

.checkoutsection .pr_selectfield__toggle:after {
    border-color: #000 !important;
}


div:not(.listing__addcart--mobile) .listing-order-form .pr_inputfield__label--animated,div:not(.listing__addcart--mobile) .singleselector__label, .cart-item__controls .pr_inputfield__label--animated {
    border-radius: 8px;
}

.pr_modal__inner {
    .pr_selectfield__selected_item {
        color: #000 !important;
    }
    .pr_selectfield__toggle:after {
        border-color: #000 !important;
    }
}

/* theme overrides */

/* header */

header.universal {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    height: 4.5em !important;
    .fullrow {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .header__inner {
        max-width: initial!important;
        padding: 0 !important;
    }

    .header__left {
        gap: 0 !important;
        grid-gap: 0 !important;
    }
}

.header__actions .socialicons {
    display: none;
}

.header-menu {
    gap: 20px;
}

.header-menu .menu-item.selected-menu-item {
    box-shadow: 0px 2px 0px 0px #fff;
}

.header-menu .menu-item a {
    padding: 0.7rem 0;
    font-family: var(--body-font-family);
    font-size: 1.4rem;
    font-weight: 700;
}

.pr_btn--md, .pr_btn {
    border-radius: 8px;
    font-family: var(--body-font-family);
    font-size: 1.6rem !important;
    font-weight: 500;
}

.header.universal .header__logo a, .header.universal .header__logo img {
    height: 48px;
}

.header__cart__count {
    background: #dd524c;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -14px;
    margin-top: -12px;
}

.product-tile-image .product-tile-quick-add-to-cart-btn .product-tile-cart-item-count {
    background: #dd524c;
    color: $white;
    border: none;
    width: 20px;
    height: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* search */ 

.header__search {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.04);
    border: none;
    font-family: var(--body-font-family);
    font-size: 1.6rem;
    font-weight: 500;
    min-width: initial;
    color: #BCBCBC;
}

/* General */
.page-content.listing {
    background: #fff;
}

.listing-gallery {
    background-color: #f6f6f8;
}

.listing__options.sizes {
    .pr_inputfield__label {
        display: none;
    }
    @include breakpoint($mobile-lg) {
        .pr_inputfield__label {
            display: initial;
        }
    }
}

.listing-gallery-viewport, .listing-gallery-menu-item {
    border: none;
    border-radius: 0px;
}

.listing-gallery-menu{ 
    padding: 16px;
}

.listing-gallery-menu-item.is-active {
    border-radius: 0px !important;

}

.product-tile-image > div {
    border-radius: 0px;
    border: none;
}

.product-tile-details {
    flex-direction: column;

    .product-tile-title {
        text-align: center;
    }
}

.report__wrapper {
    display: none;
}

.pr_btn {
    font-weight: 500 !important;
}

.pr_btn.btn--primary {
    background-color: $pdpRed;
    color: $white;
    border-color: $pdpRed;
    font-weight: 600 !important;
    font-family: var(--body-font-family);
    font-size: 1.6rem;
    border-radius: 0px;
}

/* hero */
.hero__inner {
    aspect-ratio: 11/4;
    padding-bottom: initial !important;
    @media only screen and (min-width: 989px) {
        margin: 0 auto;
        padding: 1.5rem 3em 0;
        margin-top: -1px;
    }
}

/* footer */

.footer {
    background-color: #000;
    padding-right: 2rem;
    padding-left: 2rem;

    .footer__inner, .footer__bottom {
        max-width: initial !important;
    }
}

.footer__bottomright span {
    color: #9E9E9E;
}

.footer__newsletter {
    text-align: center;
}

.footer__newsletter form {
    margin: auto;
}

.footer__newsletter h5, .heading__block h3 {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 700;
    font-size: 18px;
}
.footer__inner {
    grid-template-areas: "menu newsletter about" !important;

    @include breakpoint($mobile-lg) {
        display: inline;
    }
}

.footer__inner input[type="email"] {
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 8px 0px 0px 8px;
    padding: 10px 18px;
    color: $white;
    width: 225px;
}

.footer__bottom__inner {
    align-items: bottom;

    @include breakpoint($mobile-lg) {
        align-items: center;
        text-align: center;
        display: flex;
        justify-content: center;
    }
}

#hsForm_fbf727bd-7036-4708-bf68-4d5c0042ed24{
    margin: auto;
}
.hs-button {
    background-color: $white !important;
    padding: 10px 18px;
    border-radius: 0px 8px 8px 0px;
    color: $black;
    font-weight: 700;
    width:  max-content;
}
.hs-button:hover {
    background-color: #292929 !important;
    opacity: 0.8;
}

.hs-error-msg {
    font-size: 12px;
}

.relatedgrid_container {
    display: none;
}

.relatedgrid__products {
    justify-content: initial;
}

.color .variantselector__item.is-active:after {
        top: -1px;
        left: 21%;
}


.pr_modal__inner .submitted-message.hs-main-font-element p {
    color: #000;
}

.pr_btn.btn--primary:hover {
    background-color: #292929 !important;
    border-color: #292929 !important;
}

.product-tile-image {
    transition: linear 0.2s;
    border-radius: 12px;
}

.listing-gallery-viewport img, .listing-gallery-menu-item img {
    background-color: #f6f6f8 !important;
}

.cart-item-img {
    background-color: #f6f6f8 !important;
    border: none;
}

.relatedgrid__products .product-tile-image {
    background-color: #f6f6f8 !important;
    border-radius: 0px;
}

.listing-gallery-menu-item.is-active {
    border: solid 1px $pdpRed !important;
    border-radius: 8px;
}

.listing-gallery-menu-item {
    border-radius: 8px;
}

.listing-details .listing-header__title {
    font-weight: 700;
    color: #333333;
}

.products_carousel_wrapper .product-tile-image {
    background-color: #f6f6f8 !important;
    border-radius: 0px;
}

/* .product-tile-image:hover {
    background-color: $pdpRed !important;
} */

.footer__localization span.mr2 {
    display: none;
}

.localizationcontrols {
    border: 1px solid #262626;
    padding: 10px 18px;
    border-radius: 16px;
}

.footer__localization button > span {
    display: contents;
    padding: 16px;
}

.footer__localization button {
    gap: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer__about {
    max-width: 100%;
    width: 100%;
}

@media only screen and (min-width: 769px) {
    .footer__about .socialicons {
        justify-content: end !important;
    }
}

.footer__about .socialicons svg {
    width: 32px;
    height: 32px;
}

.footer__newsletter {

    p {
    color: $textGrey;
    font-size: 14px;
    }
}


.pr_modal__content {
    width: auto;
    @include breakpoint($mobile-lg) { 
        min-width: 100%;
    }
}

.pr_modal__contentwrapper {
    width: 100%;
}
    
.cartconfirm__total {
    margin-left: -24px;
    margin-right: -24px;
    padding: 16px 24px;
    background: #f0f0f0;
    @include breakpoint($mobile-lg) {
        margin-bottom: 48px !important;
    }
}

/* product feed */

.product-feed {
    max-width: initial !important;
    margin-top: 32px;
}


/* modal */

.getnotified .pr_modal__content {
    flex-direction: column;
}

button.getnotified {
    padding: 10px 14px 10px 12px;
    border-radius: 8px;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.15);
    font-weight: 500;
    gap: 8px;
    display: flex;
}
button.getnotified span {
    font-weight: 500;
    font-family: var(--body-font-family) !important;
    font-size: 1.6rem;
}

@include breakpoint ($mobile-lg) {

    button.getnotified {
        padding: 10px 12px !important;
    }
    
    button.getnotified span {
        display: none;
    }
}

button.getnotified:hover {
    background-color: #292929;
    opacity: 0.8;
}

.getnotified input {
    background-color: rgba(255, 255, 255, 0.07) !important;
    border: 1px solid #3c3c3c !important;
    color: #000;
    width: 100%;
    text-align: center;
    border-radius: 16px;
    padding-left: 12px;
    height: 50px;
    padding-right: 12px;
    font-size: 16px;
}

.getnotified .actions input {
    border: none !important;
    margin-top: 12px;
    border-radius: 200px;
    color: #fff;
    font-weight: 500;
    background-color: $pdpRed !important;
}

.getnotified .hs-error-msg {
    color: red;
}

/* search overrides */

.search-results .product-tile-details, .search-results .product-tile-title, .search-results .product-tile-category   {
    color: #000 !important;
}

.search-results .product-tile-image {
    background-color: #37373724 !important;
}

.search-results .product-tile-image:hover {
    background-color: $pdpRed !important;
}

/* homepage hide category titles */
.flickity-slider .product-tile .product-tile-link-wrapper .product-tile-details .product-tile-info .product-tile-category  {
    display: initial !important;
}

.product-tile .product-tile-link-wrapper .product-tile-details .product-tile-info .product-tile-category  {
    display: none !important;
}

.product-tile-info {
    margin-right: 12px;
}

.products_carousel .pr_disabled__prevbtn .pr_carousel__prevbtn .pr_btn, .products_carousel .pr_disabled__nextbtn .pr_carousel__nextbtn .pr_btn {
    background-color: transparent !important;
    opacity: 0.4;
}

.product-tile-image .product-tile-quick-add-to-cart-btn {
    border-radius: 100% !important;
    border: none !important;
    width: 32px !important;
    background-color: $white !important;
    color: black;
    height: 32px;
    display: none; // hide quick add to cart button
    box-shadow: 0px 0px 6px 0px #80808036;
}

.h3, .typ--h3 {
    font-weight: 700;
}

/* better hamburger toggle */

.hamburger-button--minus.is-active .hamburger-button-inner::after {
    bottom: 0;
    transform: rotate(-45deg);
    background-color: white;
    opacity: 1;
}

.hamburger-button--minus.is-active .hamburger-button-inner::before {
    top: 0;
    transform: rotate(45deg);
    background-color: white;
    opacity: 1;
}

.hamburger-button.is-active .hamburger-button-inner {
    background-color: transparent !important;
}

.header.universal .header__hamburger {
    position: initial;
}

.header__actions {
    min-width: 506px;
    @media screen and (max-width: 991px) {
        min-width: 105px;
    }
}

.header__hamburger .flyout-menu {
    left: 0;
    right: 0;
    width: 100vw;
    padding: 2rem 2.4rem;
    border-radius: 0 0 12px 12px;
    padding-bottom: 40px;
    .flyout-menu-item a {
        font-size: 24px;
        text-decoration: none !important;
        font-weight: 600 !important;
        text-align: left;
        height: 55px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .socialicons svg {
        height: 32px;
        width: 32px;
    }
    .custom-accordion-class .pr_accordion__icon {
        display: none;
    }

    .custom-accordion-class .pr_accordion__content {
        display: none;
    }
    .pr_accordion {
        border-bottom: none;
    }
    
    .flyout-menu-item a:after {
        content: '';
        width: 32px;
        height: 32px;
        background-image: url("chevron-right.svg");
        background-repeat: no-repeat;
        background-position: right;
    }
}

/* custom collection styles */
.inside-collection {
    .product-tile-image {
        background-color: #E22519;
        border-radius: 0px;
    }
    .product-tile-title, .product-tile-category, .product-tile-price {
        color: $white !important;
    }
    .product-tile-quick-add-to-cart-btn {
        display: none;
    }
}

.page-content.inside-studios {
    background-color: #000;
    .tile-heading {
        color: #000;
        h2 {
            display: none;
        }
        &::after {
            content: "";
            width: 100%;
            background-image: url("inside-studios-collection.jpg");
            display: block;
            padding-bottom: 100%;
            background-size: contain;
        }
    }
    .product-tile-image {
        background-color: #E22519;
        border-radius: 0px;
    }
    .product-tile-title, .product-tile-category, .product-tile-price {
        color: $white !important;
    }
    .product-tile-quick-add-to-cart-btn {
        display: none;
    }
}